import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalClose, Sheet, Typography, Button, Box } from '@mui/joy';
import { ControlledJoyInput } from 'src/components/ControlledFields';
import { useOnClickOutside } from 'src/hooks';
import { useLogging, useNotification, useDashboard } from 'src/context';
import {
  createCompany,
  updateCompany,
  useCompanies,
  useCompany,
} from 'src/hooks';

interface AddEditCompanyDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * @description     AddEditCompanyDialog component is used to display the add/edit company dialog in the admin dashboard
 * @param param0    open, onClose
 * @returns         AddEditCompanyDialog component
 */
const AddEditCompanyDialog = ({ ...props }: AddEditCompanyDialogProps) => {
  const menuRef = useRef(null);
  const log = useLogging();
  const { setNotification } = useNotification();
  const { showAddEditCompanyModal } = useDashboard();

  const { mutate: mutateAll } = useCompanies();
  const { mutate } = useCompany(
    showAddEditCompanyModal?.company?.id?.toString()
  );

  const [isLoading, setIsLoading] = useState(false);

  const { name = '', max_seats = 0 } = showAddEditCompanyModal?.company || {};

  const defaultValues = {
    name,
    max_seats,
  };

  const methods = useForm({
    values: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async (data: MedScout.Company) => {
    const isEdit = !!showAddEditCompanyModal?.company;
    setIsLoading(true);
    try {
      console.log(data);
      if (isEdit) {
        await updateCompany(
          showAddEditCompanyModal?.company?.id?.toString(),
          data
        );
        log.event('Company updated', {
          source: 'AddEditCompany',
          companyId: showAddEditCompanyModal?.company?.id,
          data,
        });
        setNotification({
          title: 'Success',
          message: 'Company updated',
          type: 'success',
        });
      } else {
        await createCompany(data);

        log.event('Company created', {
          source: 'AddEditCompany',
          data,
        });
        setNotification({
          title: 'Success',
          message: 'Company created',
          type: 'success',
        });
      }
    } catch (err) {
      log.exception('Error creating company', err);
      setNotification({
        title: 'Error',
        message: 'Error creating company',
        type: 'error',
      });
    } finally {
      mutate();
      mutateAll();
      setIsLoading(false);
      props.onClose();
    }
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={showAddEditCompanyModal?.show}
      onClose={props.onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: '100%',
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="h4" sx={{ mb: 2 }}>
              {showAddEditCompanyModal?.company ? 'Edit' : 'Add'} Company
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
                mb: '0.5rem',
                width: '100%',
              }}
            >
              <Box sx={{ flex: '2 0 0%' }}>
                <Typography
                  level="body-sm"
                  sx={{ mb: '0.5rem', fontWeight: 500 }}
                >
                  Name
                </Typography>
                <ControlledJoyInput
                  name="name"
                  placeholder="Name"
                  rules={{ required: 'This field is required' }}
                  fullWidth
                />
              </Box>
              <Box sx={{ flex: '1 0 0%' }}>
                <Typography
                  level="body-sm"
                  sx={{ mb: '0.5rem', fontWeight: 500 }}
                >
                  Max Users
                </Typography>
                <ControlledJoyInput
                  type="number"
                  min={0}
                  name="max_seats"
                  placeholder="Max Seats"
                  rules={{ required: 'This field is required' }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                gap: '0.5rem',
              }}
            >
              <Button
                variant="outlined"
                color="neutral"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading}
                type="submit"
                variant="solid"
                color="primary"
                disabled={!isDirty || !isValid}
              >
                {showAddEditCompanyModal?.company
                  ? 'Update Company'
                  : 'Create Company'}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Sheet>
    </Modal>
  );
};

export default AddEditCompanyDialog;
