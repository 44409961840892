import React, { useEffect, useState } from 'react';
import { Map, useMap, useApiIsLoaded } from '@vis.gl/react-google-maps';
import {
  useMedScoutMap,
  useProspectSearch,
  useDiscoveryMap,
  useLayoutControl,
  SelectedProspectProvider,
} from 'src/context';
import { Box, CircularProgress } from '@mui/joy';
import {
  BladeToggle,
  TerritoryPanel,
  MedScoutLoadingIcon,
  TerritoryBlade,
  AddProviderDialog,
  RepsListBlade,
  ResultsBlade,
  BLADE_NAMES,
} from 'src/components';
import {
  useGetDashboards,
  useGetMedBoundaries,
  useGetMedMapSearchResults,
} from 'src/hooks';
import { MAX_WINDOW_WIDTH, MAX_ZOOM } from 'src/components/Map/constants';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { styled } from '@mui/material';
import { NO_TERRITORY_ACTIVE_SELECTION } from 'src/components/TerritoryPanel/constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { MedMapContent, MedMapMarkers } from './components';

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 1,
  backgroundColor: 'rgba(0,0,0,0.15)',
});

const LoadingContent = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '0.625rem',
  paddingRight: '0.625rem',
  paddingLeft: '0.625rem',
});

const drawerWidth = 40;

const isLoaded = () => {
  return (
    typeof google !== 'undefined' &&
    typeof google.maps !== 'undefined' &&
    typeof google.maps.Map !== 'undefined'
  );
};

const DiscoveryMap = () => {
  const apiIsLoaded = useApiIsLoaded();
  const map = useMap();
  const { width } = useWindowSize();
  const MIN_ZOOM = width > MAX_WINDOW_WIDTH ? 5 : 4;
  const { toggleDrawer, setToggleDrawer } = useLayoutControl();
  const {
    prospectFilters: filters,
    prospectType,
    prospectMode,
  } = useProspectSearch(); // has to be in a SelectedProsectProvider

  const {
    editingTerritory,
    selectedTerritories,
    setSelectTerritories,
    createNewTerritoryMode,
  } = useDiscoveryMap();

  const [newProviders, setNewProviders] = useState(null);

  const [mapApiIsLoading, setMapApiIsLoading] = React.useState(true);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);

  useEffect(() => {
    if (!apiIsLoaded) return;
    setMapApiIsLoading(false);
  }, [apiIsLoaded, map]);

  // const { isLoading } = useGetMedBoundaries(); TODO: Do i need this?
  const { isLoading: isLoadingResults } = useGetMedMapSearchResults();
  const { data: dashboardData } = useGetDashboards();
  const {
    heatMap,
    zoom,
    center,
    mapBounds,
    setBounds,
    setMapBounds,
    setZoom,
    currentTerritory,
    drawingMode,
    isEditing,
    isCreating,
    resultsListParams,
    setResultsListParams,
    adhocTerritory,
  } = useMedScoutMap();

  // get current territory paths
  const isNew = !!currentTerritory?.polygons;
  const paths = isNew
    ? currentTerritory?.polygons
    : currentTerritory?.old_polygons;

  useEffect(() => {
    if (!currentTerritory) {
      setSelectTerritories([]);
    }
    setToggleDrawer(BLADE_NAMES.RESULTS, true);

    // TODO: This is temporary until we remove the old map
    if (currentTerritory?.id) {
      // check if currentTerritory is in selectedTerritories
      // don't add it if it is
      const isAlreadySelected = selectedTerritories.some(
        (territory) => territory.id === currentTerritory.id
      );

      if (!isAlreadySelected) {
        setSelectTerritories([currentTerritory]);
      }
    }
  }, [currentTerritory]);

  useEffect(() => {
    let containsNonExclusionPaymentsFilter = false;

    const groupedFiltersByContext = filters.reduce(
      (result, { context, value }) => {
        if (!containsNonExclusionPaymentsFilter) {
          // [id, min, max] value pattern for payments, when max is defined as 0 this is an 'exclusion payment filter'
          containsNonExclusionPaymentsFilter =
            context !== 'payments' ? true : (value?.['2'] ?? null) !== 0;
        }

        if (!result[context]) {
          result[context] = context === 'year' ? value : [value];
        } else {
          result[context] = [...result[context], value];
        }

        return result;
      },
      {}
    );

    const isEmpty = Object.keys(groupedFiltersByContext)?.length === 0;
    const sortedParams = !isEmpty
      ? Object.keys(groupedFiltersByContext).reduce((result, key) => {
          const currentParams = groupedFiltersByContext[key];
          if (Array.isArray(currentParams)) {
            result[key] = Array.from(new Set(currentParams)).sort();
          } else {
            result[key] = currentParams;
          }
          return result;
        }, {})
      : {};

    const selectedTerritoryIds = selectedTerritories.reduce(
      (result, territory) => {
        if (territory?.id?.toString() !== NO_TERRITORY_ACTIVE_SELECTION.id) {
          result.push(territory?.id);
        }
        return result;
      },
      []
    );

    const encodedParams = getSearchQueryParamsForAPI({
      type: prospectType,
      mode: prospectMode,
      ex_pr: 0,
      map: selectedTerritoryIds,
      zoom: zoom || 4,
      ...sortedParams,
    });

    setResultsListParams({
      queryParams: encodedParams,
      enabled: true,
    });
  }, [prospectType, prospectMode, filters, selectedTerritories, zoom]);

  useEffect(() => {
    // in essence this if statement checks to make sure the fitbounds doesn't do anything but load the territory
    const shouldHalt =
      !map ||
      drawingMode === 'DRAW' ||
      !paths ||
      !isLoaded() ||
      isEditing ||
      isCreating;
    if (shouldHalt) return;

    const bounds = new google.maps.LatLngBounds();
    paths.forEach((path) => {
      path.forEach((point) => {
        // Check if the point is valid
        // Point format should be { lat: number, lng: number }
        const isValidPoint =
          point &&
          typeof point.lat === 'number' &&
          typeof point.lng === 'number';

        if (isValidPoint) {
          bounds?.extend(point);
        } else {
          console.error('Invalid point', point);
        }
      });
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
      map.setCenter(bounds.getCenter());
      map.setZoom(map.getZoom());
    }
    // map.setZoom(map.getZoom()); // TODO: Not sure if I need this yet.  Leaving it here for now.
  }, [drawingMode, map, paths]);

  useEffect(() => {
    if (isEditing || isCreating) {
      setToggleDrawer(BLADE_NAMES.RESULTS, false);
    }
  }, [isEditing, isCreating]);

  const handleBoundsChanged = (bounds: any) => {
    if (!bounds || !apiIsLoaded) return;
    const { north, south, east, west } = bounds.detail.bounds;
    if (!north || !south || !east || !west) return;

    setBounds([
      [north, west],
      [south, east],
    ]);

    setMapBounds([
      [west, north],
      [east, north],
      [east, south],
      [west, south],
      [west, north],
    ]);
  };

  const handleZoomChanged = (zoom: any) => {
    if (!zoom || !apiIsLoaded) return;
    const newZoom = zoom?.detail?.zoom || MIN_ZOOM;
    setZoom(newZoom);
  };

  const noop = () => {
    return;
  };

  const addToList = async (providers, fromMap = false, onSuccess = noop) => {
    setNewProviders({
      providers: Array.isArray(providers)
        ? structuredClone(providers)
        : [{ ...providers }],
      ui_fromMap: fromMap,
      onSuccess,
    });
  };

  const handleActiveMarker = (provider_id: number | string) => {
    setActiveMarker(Number(provider_id));
  };

  // Markers are seperate on a heatmap
  const showMarkers = !isEditing && !isCreating && !heatMap;

  if (!isLoaded() || mapApiIsLoading || !apiIsLoaded) {
    return (
      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <SelectedProspectProvider>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <Map
            mapId="discovery-map" // All maps need an id
            draggableCursor="pointer"
            draggingCursor="move"
            style={{ width: '100%', height: '100%' }}
            defaultCenter={center}
            defaultZoom={MIN_ZOOM}
            zoom={zoom}
            gestureHandling={'greedy'}
            disableDefaultUI
            onBoundsChanged={handleBoundsChanged}
            onZoomChanged={handleZoomChanged}
            maxZoom={MAX_ZOOM}
            minZoom={MIN_ZOOM}
            scrollwheel
            zoomControl
            zoomControlOptions={{
              position: global.google?.maps?.ControlPosition?.RIGHT_TOP || 3,
            }}
            reuseMaps // should speed up the map
          >
            {showMarkers && (
              <MedMapMarkers
                activeMarker={activeMarker}
                setActiveMarker={setActiveMarker}
                addToList={addToList}
              />
            )}

            <MedMapContent />
          </Map>
          {/* Add Edit Panel */}
          <TerritoryPanel />
          {toggleDrawer && (
            <BladeToggle
              open={toggleDrawer?.open}
              drawer={toggleDrawer?.drawer}
              toggleOpen={() =>
                setToggleDrawer(toggleDrawer?.drawer, !toggleDrawer?.open)
              }
            >
              <Icon
                icon={
                  toggleDrawer?.open ? faAngleDoubleRight : faAngleDoubleLeft
                }
              />
            </BladeToggle>
          )}
          {isLoadingResults && (
            <LoadingContainer>
              <LoadingContent>
                <MedScoutLoadingIcon />
              </LoadingContent>
            </LoadingContainer>
          )}
        </div>
        {map && mapBounds && (
          <>
            <ResultsBlade
              open={
                toggleDrawer?.drawer === BLADE_NAMES.RESULTS &&
                toggleDrawer?.open
              }
              onClose={() => setToggleDrawer(null)}
              addToList={addToList}
              contentType={prospectType}
              searchQueryParams={resultsListParams}
              mapState={{
                bounds: mapBounds,
              }}
              onResultHover={handleActiveMarker}
              width={drawerWidth}
              updateOnMapMove={!editingTerritory && !createNewTerritoryMode}
            />
            <TerritoryBlade
              territoryId={'adhoc-territory'}
              open={
                !!adhocTerritory &&
                toggleDrawer?.drawer === BLADE_NAMES.TERRITORY_ANALTYICS &&
                toggleDrawer?.open
              }
              onClose={() => setToggleDrawer(null)}
              dashboardId={dashboardData?.results[0]?.id}
              adhoc={adhocTerritory}
              width={drawerWidth}
            />
            <RepsListBlade
              open={
                toggleDrawer?.drawer === BLADE_NAMES.REP_LIST &&
                toggleDrawer?.open
              }
              onClose={() => setToggleDrawer(null)}
              width={drawerWidth}
            />
          </>
        )}
      </Box>
      {newProviders && (
        <AddProviderDialog
          onHide={() => setNewProviders(null)}
          newProviders={newProviders}
        />
      )}
    </SelectedProspectProvider>
  );
};

export default DiscoveryMap;
