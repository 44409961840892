import {
  createContext,
  useReducer,
  useContext,
  useCallback,
  Dispatch,
} from 'react';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import theme from 'src/styles/theme';
import CssBaseline from '@mui/material/CssBaseline';

const materialTheme = materialExtendTheme(theme);

const LayoutActionTypes: { [key: string]: string } = {
  SET_SHOW_FILTER_DRAWER: 'SET_SHOW_FILTER_DRAWER',
  SET_SHOW_ADD_SAVED_SEARCH: 'SET_SHOW_ADD_SAVED_SEARCH',
  SET_APPLICATION_DEPTH: 'SET_APPLICATION_DEPTH',
  SET_SHOW_ADD_LIST_DIALOG: 'SET_SHOW_ADD_LIST_DIALOG',
  SET_SHOW_REPORT_DATA_ISSUE_DIALOG: 'SET_SHOW_REPORT_DATA_ISSUE_DIALOG',
  SET_SHOW_RESOLVE_DATA_ISSUE_DIALOG: 'SET_SHOW_RESOLVE_DATA_ISSUE_DIALOG',
  SET_PUSH_PROVIDER_TO_CRM: 'SET_PUSH_PROVIDER_TO_CRM',
  SET_PUSH_DUPLICATE_TO_CRM: 'SET_PUSH_DUPLICATE_TO_CRM',
  SET_TEST_PUSH_TO_CRM_DIALOG: 'SET_TEST_PUSH_TO_CRM_DIALOG',
  SET_TOGGLE_DRAWER: 'SET_TOGGLE_DRAWER',
};

type valueof<T> = T[keyof T];

type LayoutControlState = {
  showFilterDrawer: boolean;
  showAddSavedSearchDialog: boolean;
  showAddListDialog: boolean;
  showReportDataIssueDialog: {
    show: boolean;
    providerId?: string;
    providerType?: string;
  };
  showResolveDataIssueDialog: {
    show: boolean;
    dataIssueId?: string;
  };
  pushProviderToCRM: {
    provider: MedScout.Opportunity | null;
    integration: string | null;
  };
  testPushToCRMDialog: {
    mapping: MedScout.Mapping | null;
    isDryRun: boolean;
    handleCRMTestDialogResponse: (response: any, isDryRun: boolean) => void;
    setTestPushError: (error: string) => void;
  };
  pushDuplicateToCRM: any;
  applicationDepth: number;
  toggleDrawer: {
    drawer: string;
    open: boolean;
  } | null;
};
type LayoutActions = {
  type: keyof typeof LayoutActionTypes;
  payload: boolean | number | valueof<LayoutControlState>;
};

const initialState: LayoutControlState = {
  showFilterDrawer: false,
  showAddSavedSearchDialog: false,
  showAddListDialog: false,
  showReportDataIssueDialog: {
    show: false,
  },
  showResolveDataIssueDialog: {
    show: false,
  },
  pushProviderToCRM: null,
  testPushToCRMDialog: null,
  pushDuplicateToCRM: null,
  applicationDepth: 0,
  toggleDrawer: null,
};

const LayoutControlContext = createContext<{
  state: LayoutControlState;
  dispatch: Dispatch<LayoutActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const layoutReducer = (state: LayoutControlState, action: LayoutActions) => {
  const newState = { ...state };

  switch (action.type) {
    case LayoutActionTypes.SET_SHOW_FILTER_DRAWER:
      newState.showFilterDrawer = Boolean(action.payload);
      break;
    case LayoutActionTypes.SET_SHOW_ADD_SAVED_SEARCH:
      newState.showAddSavedSearchDialog = Boolean(action.payload);
      break;
    case LayoutActionTypes.SET_SHOW_ADD_LIST_DIALOG:
      newState.showAddListDialog = Boolean(action.payload);
      break;
    case LayoutActionTypes.SET_SHOW_REPORT_DATA_ISSUE_DIALOG:
      newState.showReportDataIssueDialog = {
        show: Boolean(action.payload.show),
        providerId: (action.payload as any).providerId,
        providerType: (action.payload as any).providerType,
      };
      break;
    case LayoutActionTypes.SET_SHOW_RESOLVE_DATA_ISSUE_DIALOG:
      newState.showResolveDataIssueDialog = {
        show: Boolean(action.payload.show),
        dataIssueId: (action.payload as any).dataIssueId,
      };
      break;
    case LayoutActionTypes.SET_PUSH_PROVIDER_TO_CRM:
      newState.pushProviderToCRM = {
        provider: (action.payload as any).provider,
        integration: (action.payload as any).integration,
      };
      break;
    case LayoutActionTypes.SET_TEST_PUSH_TO_CRM_DIALOG:
      newState.testPushToCRMDialog = {
        mapping: (action.payload as any).mapping,
        isDryRun: Boolean(action.payload.isDryRun),
        handleCRMTestDialogResponse: (action.payload as any)
          .handleCRMTestDialogResponse,
        setTestPushError: (action.payload as any).setTestPushError,
      };
      break;
    case LayoutActionTypes.SET_PUSH_DUPLICATE_TO_CRM:
      newState.pushDuplicateToCRM = action.payload as any;
      break;
    case LayoutActionTypes.SET_APPLICATION_DEPTH:
      newState.applicationDepth = Number(action.payload);
      break;
    case LayoutActionTypes.SET_TOGGLE_DRAWER: {
      const { drawer, open } = action.payload;

      // If the same drawer is already open, toggle it
      if (state?.toggleDrawer?.drawer === drawer) {
        return {
          ...state,
          toggleDrawer: {
            ...state.toggleDrawer,
            open: typeof open === 'boolean' ? open : !state.toggleDrawer?.open,
          },
        };
      }

      // Otherwise, open the specified drawer
      return {
        ...state,
        toggleDrawer: {
          drawer,
          open: typeof open === 'boolean' ? open : true, // Default to true if open is null or undefined
        },
      };
    }
  }

  return newState;
};

export const LayoutControlProvider = (props) => {
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  return (
    // ThemeProvider was removed in liu of the Joy MaterialCssVarsProvider JoyCssVarsProvider
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <LayoutControlContext.Provider value={{ state, dispatch }} {...props} />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export const useLayoutControl = () => {
  const context = useContext(LayoutControlContext);
  const {
    state: {
      showFilterDrawer,
      showAddSavedSearchDialog,
      showAddListDialog,
      showReportDataIssueDialog,
      showResolveDataIssueDialog,
      pushProviderToCRM,
      testPushToCRMDialog,
      pushDuplicateToCRM,
      toggleDrawer,
    },
    dispatch,
  } = context;

  if (!context) {
    throw new Error(
      'useLayoutControl must be used within a LayoutControlProvider'
    );
  }

  return {
    showFilterDrawer,
    showAddSavedSearchDialog,
    showAddListDialog,
    showReportDataIssueDialog,
    showResolveDataIssueDialog,
    pushProviderToCRM,
    testPushToCRMDialog,
    pushDuplicateToCRM,
    toggleDrawer,
    toggleShowFilterDrawer: () => {
      dispatch({
        type: LayoutActionTypes.SET_SHOW_FILTER_DRAWER,
        payload: !showFilterDrawer,
      });
    },
    setShowFilterDrawer: (show: boolean) =>
      dispatch({
        type: LayoutActionTypes.SET_SHOW_FILTER_DRAWER,
        payload: show,
      }),
    setShowAddSavedSearchDialog: (show: boolean) =>
      dispatch({
        type: LayoutActionTypes.SET_SHOW_ADD_SAVED_SEARCH,
        payload: show,
      }),
    setShowAddListDialog: (show: boolean) =>
      dispatch({
        type: LayoutActionTypes.SET_SHOW_ADD_LIST_DIALOG,
        payload: show,
      }),
    setShowReportDataIssueDialog: (
      reportDataIssue: LayoutControlState['showReportDataIssueDialog']
    ) =>
      dispatch({
        type: LayoutActionTypes.SET_SHOW_REPORT_DATA_ISSUE_DIALOG,
        payload: {
          show: reportDataIssue?.show,
          providerId: reportDataIssue?.providerId,
          providerType: reportDataIssue?.providerType,
        },
      }),
    setShowResolveDataIssueDialog: (
      resolveDataIssue: LayoutControlState['showResolveDataIssueDialog']
    ) =>
      dispatch({
        type: LayoutActionTypes.SET_SHOW_RESOLVE_DATA_ISSUE_DIALOG,
        payload: {
          show: resolveDataIssue?.show,
          dataIssueId: resolveDataIssue?.dataIssueId,
        },
      }),
    setPushProviderToCRM: (
      provider: MedScout.Opportunity,
      integration: string
    ) =>
      dispatch({
        type: LayoutActionTypes.SET_PUSH_PROVIDER_TO_CRM,
        payload: {
          provider,
          integration,
        },
      }),
    setTestPushToCRMDialog: (
      mapping: MedScout.Mapping,
      isDryRun: boolean,
      handleCRMTestDialogResponse: (response: any, isDryRun: boolean) => void,
      setTestPushError: (error: string) => void
    ) =>
      dispatch({
        type: LayoutActionTypes.SET_TEST_PUSH_TO_CRM_DIALOG,
        payload: {
          mapping,
          isDryRun,
          handleCRMTestDialogResponse,
          setTestPushError,
        },
      }),
    setPushDuplicateToCRM: (duplicate) =>
      dispatch({
        type: LayoutActionTypes.SET_PUSH_DUPLICATE_TO_CRM,
        payload: duplicate,
      }),

    setToggleDrawer: (drawer: string, open?: boolean | null) =>
      dispatch({
        type: LayoutActionTypes.SET_TOGGLE_DRAWER,
        payload: { drawer, open },
      }),
  };
};
