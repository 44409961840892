import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Chip, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFolderBookmark } from '@fortawesome/pro-solid-svg-icons';
import { useGetSavedSearches } from 'src/hooks';
import { Close } from '@mui/icons-material';

const SavedSearchAutocomplete = ({ selectedSearches, setSelectedSearches }) => {
  const { data: savedSearches } = useGetSavedSearches();
  const [localSelectedSearches, setLocalSelectedSearches] =
    useState(selectedSearches);

  // Sync the local state with context after delay
  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        JSON.stringify(localSelectedSearches) !==
        JSON.stringify(selectedSearches)
      ) {
        setSelectedSearches(localSelectedSearches);
      }
    }, 300); // Debounce delay of 300ms

    return () => clearTimeout(handler); // Cleanup to avoid multiple timeouts
  }, [localSelectedSearches, selectedSearches, setSelectedSearches]);

  // Update local state on selection change
  const handleSelectionChange = (event, newValue) => {
    setLocalSelectedSearches(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        flex: '1 1 100%',
      }}
    >
      <Typography
        level="body-sm"
        sx={{ fontSize: '0.875rem', fontWeight: 500 }}
      >
        View volume for:
      </Typography>
      <Autocomplete
        value={localSelectedSearches}
        onChange={handleSelectionChange}
        multiple
        placeholder="Add a Saved Search"
        limitTags={2}
        options={savedSearches || []}
        getOptionLabel={(option) => option.name}
        startDecorator={<Icon icon={faFolderBookmark} />}
        renderTags={(tag, getTagProps) =>
          tag.map((option, index) => (
            <Chip
              key={index}
              endDecorator={<Close fontSize="small" />}
              variant="plain"
              color="neutral"
              sx={{
                minWidth: 0,
                color: '#009976',
                '& .MuiChip-action': {
                  background: '#D6F6F1 !important',
                  '&:hover': {
                    background: '#CBE9E4 !important',
                  },
                },
              }}
              {...getTagProps({ index })}
            >
              {option.name}
            </Chip>
          ))
        }
        sx={{
          width: '100%',
          minWidth: '200px',
          borderRadius: '0.5rem',
        }}
      />
    </Box>
  );
};

export default SavedSearchAutocomplete;
