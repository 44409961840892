import React, { useState } from 'react';
import { Box, Container } from '@mui/joy';
import {
  DateSelect,
  TypeSelect,
  SavedSearchAutocomplete,
  SearchInput,
} from './components';
import { useSite } from 'src/context';
import { useLocalStorage } from 'src/hooks';

const PageFilterBar = () => {
  const location = 'hcp';
  const [pageFilterBar, setPageFilterBar] = useLocalStorage('pageFilterBar', {
    [location]: {
      search: '',
      selectedType: 'claims',
      selectedDate: {
        lte: '2024',
        gte: '2019',
      },
      selectedSearches: [],
    },
  });

  const { search, selectedType, selectedDate, selectedSearches } =
    pageFilterBar[location]
      ? pageFilterBar[location]
      : {
          search: '',
          selectedType: 'claims',
          selectedDate: {
            lte: '2024',
            gte: '2019',
          },
          selectedSearches: [],
        };

  const setSearch = (search) => {
    setPageFilterBar({
      [location]: {
        ...pageFilterBar[location],
        search,
      },
    });
  };

  const setSelectedType = (selectedType) => {
    setPageFilterBar({
      [location]: {
        ...pageFilterBar[location],
        selectedType,
      },
    });
  };

  const setSelectedDate = (selectedDate) => {
    setPageFilterBar({
      [location]: {
        ...pageFilterBar[location],
        selectedDate,
      },
    });
  };

  const setSelectedSearches = (selectedSearches) => {
    setPageFilterBar({
      [location]: {
        ...pageFilterBar[location],
        selectedSearches,
      },
    });
  };

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: 'flex',
        padding: '0.5rem',
        gap: '0.5rem',
      }}
    >
      <SearchInput search={search} setSearch={setSearch} />
      {/* Divider wouldn't show, so we old skool it. */}
      <Box
        sx={{
          height: 'auto',
          width: '1px',
          minWidth: '1px',
          backgroundColor: '#636B744D',
          marginInline: '0.5rem',
        }}
      />
      <SavedSearchAutocomplete
        selectedSearches={selectedSearches}
        setSelectedSearches={setSelectedSearches}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%',
          flex: 2,
        }}
      >
        <TypeSelect
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
        <DateSelect
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Container>
  );
};

export default PageFilterBar;
