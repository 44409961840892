import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { PROFILE_QUERY_MAP } from 'src/hooks';
import { useProspectSearch, useAuth } from 'src/context';
import { snakeCase } from 'src/utils';

const REQ_MAP = {
  years: 'year',
  categories: 'cat',
  codes: 'code',
};

export function useGetProfileDetails({ id, contentType }) {
  const { user } = useAuth();
  const queryParams = getSearchQueryParamsForAPI({
    company_id: user?.company?.id,
  });

  return useQuery(
    [PROFILE_QUERY_MAP.get('profile_details'), id, contentType, queryParams],
    () => {
      return request({
        url: `/v1/${contentType}/${id}${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!id && !!contentType,
    }
  );
}

const dataTypeToUrlMap = {
  affiliations: 'affiliations',
  drg: 'drg',
  apc: 'apc',
  icd: 'icd',
  icdp: 'icdp',
  cpt: 'cpt',
  hcpcs: 'hcpcs',
  payments: 'payments',
  prescriptions: 'drug',
  referrals: 'inbound-referrals',
  studies: 'studies',
};

export const useGetProfileTabData = ({ filters, enabled = true }) => {
  const router = useRouter();
  const { queryString } = useProspectSearch();
  const {
    id,
    type,
    view,
    relationship = 'inbound',
  } = router.query as {
    id: string;
    type: string;
    view: string;
    relationship: 'inbound' | 'outbound';
  };
  // Special case for patient patterns; we need to use the relationship to determine the view
  // TODO: This is a hack. Fix this in the API whe we fix the other keys
  const relativeView =
    view === 'patient_patterns' ? `${relationship}_${view}` : view;
  const queryParams = useMemo(() => {
    if (!filters) return queryString || '';
    // TODO: We need to fix the keys in the app. Category vs Categories vs Cat. REQ_MAP is a stop gap
    const paramMap = Object.entries(filters).reduce(
      (acc, [k, v]: [string, any]) => {
        // TODO: This is a hack. Fix this too :( (see above)
        const key = (view !== 'patient_patterns' && REQ_MAP[k]) || k;
        const value = Object.keys(v.selectedDict)
          .filter((key) => v.selectedDict[key])
          .map((key) => (isNaN(Number(key)) ? key : Number(key)));
        if (value.length > 0) acc[key] = value;

        return acc;
      },
      {}
    );

    return getSearchQueryParamsForAPI(paramMap, queryString || '');
  }, [filters, queryString]);

  return useQuery(
    [
      PROFILE_QUERY_MAP.get('profile_details'),
      { type, id, relativeView, queryParams },
    ],
    () =>
      request({
        url: `/v1/${type}/${id}/${dataTypeToUrlMap[relativeView]}${queryParams}`,
        data: {},
        method: 'GET',
      }),
    {
      enabled: !!dataTypeToUrlMap[relativeView] && enabled,
      refetchOnWindowFocus: false,
      staleTime: 60_000,
    }
  );
};

export const useReportDataIssue = () => {
  return useMutation<
    any,
    unknown,
    { providerId: string; contentType; reason; details }
  >(({ providerId, contentType, reason, details }) => {
    return request({
      url: `v1/data-issue`,
      method: 'POST',
      data: {
        provider_id: providerId,
        content_type: snakeCase(contentType),
        reason,
        details,
      },
    });
  });
};
