import React from 'react';
import { useRouter } from 'next/router';
import { Box } from '@mui/joy';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const CenterCaseLeakage = () => {
  const router = useRouter();
  const { id } = router.query as { id: string };

  const queryParams = getSearchQueryParamsForAPI({
    _hospital_choice: JSON.stringify(id),
    embedded: true,
  });

  return (
    <Box>
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <iframe
          src={`https://app.hex.tech/832fe7f8-be53-47a2-9281-647978dda119/app/d77949cb-d6c0-4c19-b12d-e7f348152e80/latest${queryParams}`}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    </Box>
  );
};

export default CenterCaseLeakage;
