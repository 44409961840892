import React, { useState, useEffect } from 'react';
import { Box, Typography, Input } from '@mui/joy';
import { ManageSearchRounded } from '@mui/icons-material';

const SearchInput = ({ search, setSearch }) => {
  const [localSearch, setLocalSearch] = useState(search);

  // Sync local input with context state after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      if (localSearch !== search) {
        setSearch(localSearch);
      }
    }, 300); // Adjust delay as needed

    return () => clearTimeout(handler); // Cleanup on unmount or change
  }, [localSearch, search, setSearch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
        width: '100%',
      }}
    >
      <Typography
        level="body-sm"
        sx={{ fontSize: '0.875rem', fontWeight: 500 }}
      >
        Filter Providers
      </Typography>
      <Input
        value={localSearch}
        sx={{ width: '100%', borderRadius: 'md' }}
        placeholder="by Name, Specialty or Location"
        startDecorator={<ManageSearchRounded sx={{ color: '#636B74' }} />}
        onChange={(e) => {
          setLocalSearch(e.target.value);
        }}
      />
    </Box>
  );
};

export default SearchInput;
