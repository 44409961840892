import { Box, Typography } from '@mui/joy';
import React from 'react';
import { NewGridVolumeColumnMenu } from '../TableControls/components';
interface NewTableSecondaryHeaderProps {
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  showVolumeMenu?: boolean;
  isMobile?: boolean;
}

const NewTableSecondaryHeader = ({
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  showVolumeMenu = false,
  isMobile = false,
}: NewTableSecondaryHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%',
      }}
    >
      {!isMobile ? (
        <Box
          sx={{
            flex: 1,
            flexShrink: 0,
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            level="h4"
            sx={{
              color: '#171A1C',
            }}
          >
            Apply Codes & Filters
          </Typography>
          <Typography
            level="title-sm"
            sx={{
              color: '#555E68',
            }}
          >
            See Claims & Patient volumes for your selected criteria.
          </Typography>
        </Box>
      ) : (
        <Typography
          level="title-md"
          sx={{
            color: '#171A1C',
          }}
        >
          Apply Codes & Filters
        </Typography>
      )}
      {showVolumeMenu && (
        <NewGridVolumeColumnMenu
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
        />
      )}
    </Box>
  );
};

export default NewTableSecondaryHeader;
