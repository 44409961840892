import {
  GridColDef,
  GridValueGetterParams,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { ProspectService } from 'src/services';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircle,
  faDotCircle,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { format as formatDate } from 'date-fns';
import { TypeIcon } from 'src/components';

import CrmCell from './CrmCell';
import NameCell from './NameCell';
import ProviderNameCell from './ProviderNameCell';
import TagCell from './TagCell';
import AddressCell from './AddressCell';
import PhoneCell from './PhoneCell';
import EmailCell from './EmailCell';
import AffiliationCell from './AffiliationCell';
import VolumeCell from './VolumeCell';
import SearchVolumeCell from './SearchVolumeCell';
import StatusCell from './StatusCell';
import NotesCell from './NotesCell';
import CrmLinkCell from './CrmLinkCell';
import CommandCell from './CommandCell';
import StreetAddressCell from './StreetAddressCell';
import ListNameCell from './ListNameCell';
import SmartListTypeCell from './SmartListTypeCell';
import VolumeHeader from './VolumeHeader';
import GroupsCell from './GroupsCell';
import VolumeShifterValueCell from './VolumeShifterValueCell';
import SmartListSavedSearchCell from './SmartListSavedSearchCell';
import TrendVolumeCell from './TrendVolumeCell';
import OverlayColumn from './OverlayColumn';
import SpecialityCell from './SpecialityCell';
import TrendVolumeHeader from './TrendVolumeHeader';
import Header from './Header';
import { Typography } from '@mui/joy';
import StringCell from './StringCell';
import CityStateCell from './CityStateCell';
import { exists } from 'src/utils';
import SecondaryAddressCell from './SecondaryAddressCell';
import HeaderVolume from './HeaderVolume';

export { default as CrmSyncCell } from './CrmSyncCell';

export const TableColumn: {
  [key: string]: (
    field: string,
    name: string,
    valueGetter?: (arg0: GridValueGetterParams) => any
  ) => GridColDef;
} = {
  savedSearch: (field, name) => ({
    field,
    headerName: name,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    renderHeader: (params) => <HeaderVolume {...params} />,
    align: 'center',
    headerAlign: 'center',
    sortComparator: ProspectService.sortByVolume,
    valueGetter: ({ row }) => row[field] || 0,
    sortable: true,
    type: 'string',
    flex: 1,
  }),
  volume: (field, name) => ({
    field,
    headerName: name,
    groupable: false,
    sortable: true,
    sortComparator: ProspectService.sortByVolume,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    renderHeader: (params) => <HeaderVolume {...params} />,
    renderCell: (params) => <VolumeCell {...params} />,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    sortingOrder: ['desc', 'asc', null],
  }),
  searchVolume: (field, name) => ({
    field,
    headerName: name,
    groupable: false,
    sortable: true,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    renderHeader: (params) => <HeaderVolume {...params} />,
    renderCell: (params) => <SearchVolumeCell {...params} searchId={field} />,
    type: 'string',
    resizable: false,
    sortingOrder: ['desc', 'asc', null],
  }),
  trendVolume: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <TrendVolumeHeader {...params} />,
    groupable: false,
    sortable: false,
    headerClassName: 'MuiDataGrid-lightHeader',
    cellClassName: 'MuiDataGrid-lightCell',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => <TrendVolumeCell {...params} field={field} />,
    type: 'string',
    resizable: false,
    sortingOrder: ['desc', 'asc', null],
  }),
  overlayColumn: (field, name) => ({
    field: 'overlay',
    headerName: '',
    width: 0,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    resizable: false,
    renderCell: (params) => <OverlayColumn {...params} />,
  }),
  type: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <TypeIcon type={row.object_type} style={{ marginRight: '0' }} />
    ),
    width: 24,
    align: 'center',
  }),
  crm: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    groupable: false,
    sortable: false,
    renderCell: (params) => <CrmCell {...params} />,
    width: 165,
  }),
  name: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <NameCell {...params} />,
    flex: 1,
    minWidth: 350,
  }),
  providerName: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <ProviderNameCell {...params} />,
    minWidth: 175,
  }),
  tags: (field, name) => ({
    field,
    headerName: name,
    sortable: false,
    groupable: false,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <TagCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  specialty: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: (params) => <SpecialityCell {...params} />,
    valueGetter: ({ row }) => row?.specialty || row?.focus || 'n/a',
    minWidth: 200,
  }),
  focus: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row?.focus || 'n/a'}</Typography>
    ),
    flex: 1,
    minWidth: 150,
  }),
  institution: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row?.institution || 'n/a'}</Typography>
    ),
    flex: 1,
    minWidth: 110,
  }),
  graduationYear: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row?.graduation_year || 'n/a'}</Typography>
    ),
    flex: 1,
    minWidth: 110,
  }),
  patients: (field, name) => ({
    field,
    headerName: name,
    align: 'center',
    headerAlign: 'center',
    renderHeader: (params) => <Header {...params} />,
    sortComparator: ProspectService.sortByVolume,
    type: 'string',
    width: 110,
  }),
  affiliations: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.hcp?.affiliations || row.affiliations || [],
    valueFormatter: ({ value }) =>
      value
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name)
        .join(', '),
    renderCell: (params) => <AffiliationCell {...params} />,
    flex: 1,
    minWidth: 110,
  }),
  groups: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.hcp?.groups || [],
    valueFormatter: ({ value }) =>
      value
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name)
        .join(', '),
    renderCell: (params) => <GroupsCell {...params} />,
    flex: 1,
    minWidth: 250,
  }),
  location: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row.location || 'n/a'}</Typography>
    ),
    minWidth: 150,
  }),
  address: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) =>
      [
        value.address_line_1,
        value.address_line_2,
        value.city,
        value.state,
        value.zip_code,
      ].join(', '),
    renderCell: (params) => <AddressCell {...params} />,
  }),
  cityAndState: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row.location,
    valueFormatter: ({ value }) => [value.city, value.state].join(', '),
    renderCell: ({ row }) => <CityStateCell row={row} />,
    align: 'right',
    headerAlign: 'right',
  }),
  streetAddress: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) =>
      !value.address_line_2
        ? value.address_line_1
        : [value.address_line_1, value.address_line_2].join(', '),
    renderCell: (params) => <StreetAddressCell {...params} />,
  }),
  secondaryAddresses: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    minWidth: 150,
    sortable: false,
    groupable: false,
    renderCell: (params) => <SecondaryAddressCell {...params} />,
  }),
  string: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    flex: 1,
  }),
  city: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const provider = row.provider ? row.provider : row;
      return <Typography level="body-sm">{provider.city}</Typography>;
    },
    minWidth: 150,
  }),
  state: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => {
      const provider = row.provider ? row.provider : row;
      return <Typography level="body-sm">{provider.state}</Typography>;
    },
    minWidth: 150,
  }),
  zip: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row.zip_code}</Typography>
    ),
    sortable: false,
    minWidth: 150,
  }),
  phone: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    sortable: false,
    groupable: false,
    renderCell: (params) => <PhoneCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  email: (field, name) => ({
    field,
    headerName: name,
    groupable: false,
    renderCell: (params) => <EmailCell {...params} />,
    flex: 1,
    minWidth: 150,
  }),
  npi: (field, name) => ({
    field,
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    renderCell: ({ row }) => (
      <Typography level="body-sm">{row.provider_id || 'n/a'}</Typography>
    ),
    flex: 1,
    minWidth: 150,
    sortable: false,
    groupable: false,
  }),
  isActive: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) =>
      row.is_active ? (
        <Icon icon={faCircle} color="green" />
      ) : (
        <Icon icon={faDotCircle} color="red" />
      ),
    align: 'center',
    headerAlign: 'center',
  }),
  managers: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: false,
    valueGetter: ({ row }) => {
      const managersList = row.company?.managers.map((manager) => {
        return `${manager.first_name} ${manager.last_name}`;
      });

      return exists(row.company.managers) ? managersList.join(', ') : 'n/a';
    },
  }),
  boolean: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) => (row[field] ? <Icon icon={faCheck} /> : null),
    align: 'center',
    headerAlign: 'center',
  }),
  dateAdded: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) => (
      <Typography level="body-sm">
        {row[field] ? formatDate(new Date(row[field]), 'PP') : ''}
      </Typography>
    ),
    minWidth: 175,
  }),
  date: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    renderCell: ({ row }) => (
      <Typography level="body-sm">
        {row[field] ? formatDate(new Date(row[field]), 'PPp') : ''}
      </Typography>
    ),
    minWidth: 175,
  }),
  key: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    align: 'center',
    headerAlign: 'center',
  }),
  simpleName: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
  }),
  notes: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: false,
    groupable: false,
    renderCell: (params) => <NotesCell {...params} />,
    flex: 1,
  }),
  listName: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    valueGetter: ({ row }) => row.name,
    renderCell: ({ row }) => <ListNameCell row={row} />,
  }),
  smartListType: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    width: 200,
    renderCell: ({ value }) => {
      return <SmartListTypeCell value={value} />;
    },
  }),
  volumeShifterValue: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: true,
    renderCell: ({ row }) => <VolumeShifterValueCell row={row} field={field} />,
    align: 'center',
    flex: 0.33,
  }),
  smartListSavedSearch: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    valueGetter: ({ row }) => row.saved_search,
    renderCell: (props) => <SmartListSavedSearchCell {...props} />,
  }),
  numItems: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    flex: 1,
    valueGetter: ({ row }) => row.num_items,
  }),
  totalPatients: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: true,
    renderCell: ({ row }) => <StringCell field={field} row={row} />,
    valueGetter: ({ row }) => row.total_patients,
    align: 'right',
    headerAlign: 'right',
    flex: 0.25,
    minWidth: 110,
  }),
  totalClaims: (field, name) => ({
    headerName: name,
    renderHeader: (params) => <Header {...params} />,
    field,
    sortable: true,
    renderCell: ({ row }) => <StringCell field={field} row={row} />,
    valueGetter: ({ row }) => row.total_claims,
    align: 'right',
    headerAlign: 'right',
    flex: 0.25,
    minWidth: 110,
  }),
};

export const TableSettings: Partial<DataGridPremiumProps> = {
  slots: {
    columnSortedAscendingIcon: () => <Icon icon={faChevronUp} size="sm" />,
    columnSortedDescendingIcon: () => <Icon icon={faChevronDown} size="sm" />,
  },
};

const TableCell = {
  Name: NameCell,
  ProviderName: ProviderNameCell,
  Tag: TagCell,
  Address: AddressCell,
  Phone: PhoneCell,
  Email: EmailCell,
  Affiliation: AffiliationCell,
  Volume: VolumeCell,
  Status: StatusCell,
  Notes: NotesCell,
  CrmLink: CrmLinkCell,
  Commands: CommandCell,
  VolumeHeader: VolumeHeader,
};

export { TableCell, OverlayColumn };
