import { createContext, ReactNode, useContext, useReducer } from 'react';

const SiteActionTypes: { [key: string]: string } = {
  SET_PAGE_FILTER_BAR: 'SET_PAGE_FILTER_BAR',
};

type SiteState = {
  pageFilterBar: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
};

const initialState: SiteState = {
  pageFilterBar: {
    search: '',
    selectedDate: {
      lte: '2024',
      gte: '2019',
    },
    selectedType: 'claims',
    selectedSearches: [],
  },
};

const SiteContext = createContext<{
  state: SiteState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const siteReducer = (state: SiteState, action: any) => {
  switch (action.type) {
    case SiteActionTypes.SET_PAGE_FILTER_BAR: {
      return {
        ...state,
        pageFilterBar: {
          ...state.pageFilterBar,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(siteReducer, initialState);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error('useSite must be used within a SiteProvider');
  }

  const { state, dispatch } = context;

  const setPageFilterBar = (value) => {
    console.log('setPageFilterBar', {
      ...state.pageFilterBar,
      ...value,
    });
    dispatch({
      type: SiteActionTypes.SET_PAGE_FILTER_BAR,
      payload: value,
    });
  };

  return {
    state,
    setPageFilterBar,
  };
};
